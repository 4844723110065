/** @format */

import axios from "axios";
import { BASE_URL } from "../Host";

export const GetAllForm = async (adminToken, role_id) => {
	try {
		const res = await axios.get(BASE_URL + "form/getAllFormForAdmin", {
			headers: {
				"x-access-token": `${adminToken}`,
				"Content-Type": "multipart/form-data",
			},
		});

		return res;
	} catch (error) {
		console.error("Error fetching users:", error);
		throw error;
	}
};



export const DeleteForm = async (form_id) => {
	return await axios.post(
		`${BASE_URL}/form/deleteForm`,
		{ form_id: form_id },
		{
			headers: {
				"x-access-token": `${localStorage.getItem("adminToken")}`,
				"Content-Type": "multipart/form-data",
			},
		}
	);
};

export const CreateForm = async (formData) => {
	return await axios.post(BASE_URL + "form/createForm", formData, {});
};

export const GetFormById = async (id) => {
	return await axios.get(BASE_URL + `form/findFormById/${id}`);
};

export const UpdateForm = async (id, formData) => {
	return await axios.put(BASE_URL + `form/editForm/${id}`, formData, {
		headers: {
			"x-access-token": `${localStorage.getItem("adminToken")}`,
			"Content-Type": "multipart/form-data",
		},
	});
};
