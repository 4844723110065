/** @format */

import React, { useEffect, useLayoutEffect, useState } from "react";
import { Button } from "primereact/button";
import { Box } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { Card } from "primereact/card";
import { Table } from "antd";
import moment from "moment";
import { IoArrowForwardCircleOutline } from "react-icons/io5";
import {
	GetCorporateById,
	GetEmployeesByCorporateId,
	GetBranchByCorporateId,
	GetListOfSharedDataByCorporateId,
} from "../../services/Api/Corporate";

const ViewCorporate = () => {
	const navigate = useNavigate();
	const { id } = useParams();
	const [userData, setUserData] = useState([]);
	const [employeeData, setEmployeeData] = useState([]);
	const [branchData, setBranchData] = useState([]);
	const [sharedData, setSharedData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [tableParams, setTableParams] = useState({
		pagination: {
			current: 1,
			pageSize: 20,
		},
		sortField: null,
		sortOrder: null,
	});

	useLayoutEffect(() => {
		setLoading(true);
		GetCorporateById(id)
			.then((res) => {
				setUserData(res.data.data);
				console.log("user", res.data.data);
			})
			.catch((err) => {
				console.log(err, "error");
			})
			.finally(() => {
				setLoading(false);
			});
	}, [id]);

	const handleTableChange = (pagination, filters, sorter) => {
		setTableParams({
			pagination,
			sortField: sorter.field,
			sortOrder: sorter.order,
		});
	};

	const getData = async () => {
		try {
			let result = await GetEmployeesByCorporateId(id);
			const dataWithIndex = result.data.data.map((item, index) => ({
				...item,
				autoIncrementId: index + 1,
			}));
			setEmployeeData(dataWithIndex);
			console.log("userbooking==>", dataWithIndex);
		} catch (e) {
			console.log(e);
		}
	};

	const getBranchData = async () => {
		try {
			let result = await GetBranchByCorporateId(id);
			const dataWithIndex = result.data.data.map((item, index) => ({
				...item,
				autoIncrementId: index + 1,
			}));
			setBranchData(dataWithIndex);
			console.log("userbooking==>", dataWithIndex);
		} catch (e) {
			console.log(e);
		}
	};

	const getSharedData = async () => {
		try {
			let result = await GetListOfSharedDataByCorporateId(id);
			const dataWithIndex = result.data.data.map((item, index) => ({
				...item,
				autoIncrementId: index + 1,
			}));
			setSharedData(dataWithIndex);
			console.log("userbooking==>", dataWithIndex);
		} catch (e) {
			console.log(e);
		}
	};

	useEffect(() => {
		getData();
		getBranchData();
		getSharedData()
	}, [id]);

	const navigateToUser = () => {
		navigate("/corporate");
	};

	const columns = [
		{
			title: "ID",
			dataIndex: "autoIncrementId",
			key: "id",
		},
		{
			title: "Name",
			dataIndex: ["user_profile", "name"],
			key: "booking_id",
		},
		{
			title: "Email",
			dataIndex: "email",
			key: "booking_id",
		},
		{
			title: "Mobile",
			dataIndex: ["user_profile", "mobile"],
			width: "20%",
			render: (mobile) => mobile || "---",
		},
		{
			title: "Account Creation Date",
			dataIndex: "created_at",
			key: "date",
			render: (date) => moment(date).format("YYYY-MM-DD HH:mm:ss"),
		},
		{
			title: "",
			key: "redirect",
			render: (text, record) => (
				<IoArrowForwardCircleOutline
					style={{ fontSize: "20px", cursor: "pointer" }}
					className="redirect_button"
					onClick={() => navigate(`/viewUser/${record.id}`)}
				/>
			),
		},
	];

	const sharedColumns = [
		{
			title: "ID",
			dataIndex: "autoIncrementId",
			key: "id",
		},
		{
			title: "Name",
			dataIndex: ["user_shared_data","user_profile", "name"],
	
		},
		{
			title: "Email",
			dataIndex: ["user_shared_data", "email"],
		},
		{
			title: "Mobile",
			dataIndex: ["user_shared_data","user_profile", "mobile"],
			width: "20%",
			render: (mobile) => mobile || "---",
		},
		{
			title: "Date",
			dataIndex: "created_at",
			key: "date",
			render: (date) => moment(date).format("YYYY-MM-DD HH:mm:ss"),
		},
		{
			title: "",
			key: "redirect",
			render: (text, record) => (
				<IoArrowForwardCircleOutline
					style={{ fontSize: "20px", cursor: "pointer" }}
					className="redirect_button"
					onClick={() => navigate(`/viewUser/${record.user_id}`)}
				/>
			),
		},
	];

	const branchColumns = [
		{
			title: "ID",
			dataIndex: "autoIncrementId",
			key: "id",
		},
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
			render: (name) => name || "---",
		},
		{
			title: "Email",
			dataIndex: "email",
			key: "email",
			render: (email) => email || "---",
		},
		{
			title: "Company Name",
			dataIndex: "company_name",
		},
		{
			title: "Date",
			dataIndex: "created_at",
			key: "date",
			render: (date) => moment(date).format("YYYY-MM-DD HH:mm:ss"),
		},
		{
			title: "",
			key: "redirect",
			render: (text, record) => (
				<IoArrowForwardCircleOutline
					style={{ fontSize: "20px", cursor: "pointer" }}
					className="redirect_button"
					onClick={() => navigate(`/view-employee/${record.id}`)}
				/>
			),
		},
	];

	return (
		<Box>
			<Box
				display="flex"
				justifyContent="space-between"
				alignItems="center"
				marginBottom="30px"
			>
				<div>
					<h3 className="page-title">CORPORATE MANAGEMENT</h3>
					<p className="page-sub-title">
						View Information related with Corporate
					</p>
				</div>
				<div>
					<Button
						icon="pi pi-arrow-left"
						severity="secondary"
						onClick={navigateToUser}
						style={{ borderRadius: "5px", height: "47px" }}
					>
						<span style={{ marginLeft: "5px" }}>Return to Corporate</span>
					</Button>
				</div>
			</Box>

			<Form className="admin_details_form">
				<div style={{ display: "flex", flexDirection: "column" }}>
					<Card
						style={{ width: "100%", marginRight: "20px", marginBottom: "40px" }}
					>
						<div
							style={{
								display: "grid",
								gridTemplateColumns: "repeat(4, 1fr)",
								gap: "20px",
								marginBottom: "40px",
							}}
						>
							<div>
								<h5
									style={{
										fontSize: "0.9rem",
										fontFamily: "Cerebri Sans,sans-serif",
										fontWeight: "700",
										marginTop: "14px",
										color: "black",
									}}
								>
									Corporate Owner Name:
								</h5>
								<p>{userData?.name || "---"}</p>
							</div>
							<div>
								<h5
									style={{
										fontSize: "0.9rem",
										fontFamily: "Cerebri Sans,sans-serif",
										fontWeight: "700",
										marginTop: "14px",
										color: "black",
									}}
								>
									Email
								</h5>
								<p>{userData?.email || "---"}</p>
							</div>

							<div>
								<h5
									style={{
										fontSize: "0.9rem",
										fontFamily: "Cerebri Sans,sans-serif",
										fontWeight: "700",
										marginTop: "14px",
										color: "black",
									}}
								>
									Company Name:
								</h5>
								<p>{userData?.company_name || "---"}</p>
							</div>

							<div>
								<h5
									style={{
										fontSize: "0.9rem",
										fontFamily: "Cerebri Sans,sans-serif",
										fontWeight: "700",
										marginTop: "14px",
										color: "black",
									}}
								>
									Mobile:
								</h5>
								<p>{userData?.user_profile?.mobile || "---"}</p>
							</div>
						</div>
					</Card>
					<Card>
						<div className="admin_profile">
							<Form className="admin_details_form">
								<div>
									<h5 style={{ marginBottom: "20px" }}>
										View all the Employees In with {userData?.company_name}
									</h5>

									<Table
										columns={columns}
										rowKey={(record) => record.id}
										dataSource={employeeData || []}
										pagination={tableParams.pagination}
										loading={loading}
										onChange={handleTableChange}
									/>
								</div>
							</Form>
						</div>
					</Card>
					<Card style={{marginTop:"30px"}}>
						<div className="admin_profile">
							<Form className="admin_details_form">
								<div>
									<h5 style={{ marginBottom: "20px" }}>
										View all the Contract Employee
									</h5>

									<Table
										columns={sharedColumns}
										rowKey={(record) => record.id}
										dataSource={sharedData || []}
										pagination={tableParams.pagination}
										loading={loading}
										onChange={handleTableChange}
									/>
								</div>
							</Form>
						</div>
					</Card>
					<Card style={{marginTop:"30px"}}>
						<div className="admin_profile">
							<Form className="admin_details_form">
								<div>
									<h5 style={{ marginBottom: "20px" }}>
										View all the Branches in {userData?.company_name}
									</h5>

									<Table
										columns={branchColumns}
										rowKey={(record) => record.id}
										dataSource={branchData || []}
										pagination={tableParams.pagination}
										loading={loading}
										onChange={handleTableChange}
									/>
								</div>
							</Form>
						</div>
					</Card>
				</div>
			</Form>
		</Box>
	);
};

export default ViewCorporate;
