/** @format */

import React, { useState } from "react";
import {
	Box,
	TextField,
	Typography,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Switch,
	Select,
	MenuItem,
	IconButton,
	Card,
	FormControlLabel,
} from "@mui/material";
import {
	ExpandMore,
	Add,
	Delete,
	ArrowLeftOutlined,
	DragIndicator,
} from "@mui/icons-material";
import { useNavigate } from "react-router";
import { CreateForm } from "../../services/Api/FormApi";
import "./FormBuilder.scss";
import { message, Button, Tooltip, Flex } from "antd";
import { FaPlus } from "react-icons/fa";
import { FiServer } from "react-icons/fi";
import { DeleteOutlined } from "@ant-design/icons";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const FormBuilder = () => {
	const navigate = useNavigate();
	const [formTitle, setFormTitle] = useState("");
	const [formDescription, setFormDescription] = useState("");
	const [formPrice, setFormPrice] = useState("");
	const [sections, setSections] = useState([]);

	const [questions, setQuestions] = useState([
		{
			questionText: "",
			type: "TEXT",
			options: [],
			isRequired: false,
			scaleMin: null,
			scaleMax: null,
			lowLabel: "",
			highLabel: "",
		},
	]);

	const addQuestion = () => {
		setQuestions([
			...questions,
			{
				questionText: "",
				type: "TEXT",
				options: [],
				isRequired: false,
				scaleMin: null,
				scaleMax: null,
				lowLabel: "",
				highLabel: "",
			},
		]);
	};

	const addSection = () => {
		setSections([
			...sections,
			{
				title: "",
				questions: [
					{
						questionText: "",
						type: "TEXT",
						options: [],
						isRequired: false,
						scaleMin: null,
						scaleMax: null,
						lowLabel: "",
						highLabel: "",
					},
				],
			},
		]);
	};

	const deleteQuestion = (index) => {
		const updatedQuestions = [...questions];
		updatedQuestions.splice(index, 1);
		setQuestions(updatedQuestions);
	};

	const updateStandaloneQuestion = (index, field, value) => {
		const updatedQuestions = [...questions];
		updatedQuestions[index][field] = value;
		setQuestions(updatedQuestions);
	};

	// 🔹 Delete Section
	const deleteSection = (sectionIndex) => {
		setSections(sections.filter((_, i) => i !== sectionIndex));
	};

	// 🔹 Add Question to Section
	const addQuestionToSection = (sectionIndex) => {
		const updatedSections = [...sections];
		updatedSections[sectionIndex].questions.push({
			questionText: "",
			type: "TEXT",
			options: [],
			isRequired: false,
			scaleMin: null,
			scaleMax: null,
			lowLabel: "",
			highLabel: "",
		});
		setSections(updatedSections);
	};

	const updateSectionQuestion = (sectionIndex, questionIndex, field, value) => {
		const updatedSections = [...sections];
		updatedSections[sectionIndex].questions[questionIndex][field] = value;
		setSections(updatedSections);
	};

	// 🔹 Delete Question from Section
	const deleteSectionQuestion = (sectionIndex, questionIndex) => {
		const updatedSections = [...sections];
		updatedSections[sectionIndex].questions.splice(questionIndex, 1);
		setSections(updatedSections);
	};

	const deleteOption = (sectionIndex, questionIndex, optionIndex) => {
		const updatedSections = [...sections];
		updatedSections[sectionIndex].questions[questionIndex].options.splice(
			optionIndex,
			1
		);
		setSections(updatedSections);
	};

	const navigateToForm = () => {
		navigate("/form");
	};

	// 🔹 Submit Form Data
	const createForm = async () => {
		const formData = {
			name: formTitle,
			description: formDescription,
			price: formPrice,
			general_questions: questions.map((q) => ({
				question_text: q.questionText,
				type: q.type,
				options:
					q.type === "MULTIPLE_CHOICE" ||
					q.type === "CHECKBOX" ||
					q.type === "DROPDOWN"
						? q.options
						: null,
				scaleMin: q.type === "LINEAR_SCALE" ? q.scaleMin : null,
				scaleMax: q.type === "LINEAR_SCALE" ? q.scaleMax : null,
				lowLabel: q.type === "LINEAR_SCALE" ? q.lowLabel : null,
				highLabel: q.type === "LINEAR_SCALE" ? q.highLabel : null,
				is_required: q.isRequired,
			})),
			sections: sections.map((section) => ({
				title: section.title,
				questions: section.questions.map((q) => ({
					question_text: q.questionText,
					type: q.type,
					options:
						q.type === "MULTIPLE_CHOICE" ||
						q.type === "CHECKBOX" ||
						q.type === "DROPDOWN"
							? q.options
							: null,
					scaleMin: q.type === "LINEAR_SCALE" ? q.scaleMin : null,
					scaleMax: q.type === "LINEAR_SCALE" ? q.scaleMax : null,
					lowLabel: q.type === "LINEAR_SCALE" ? q.lowLabel : null,
					highLabel: q.type === "LINEAR_SCALE" ? q.highLabel : null,
					is_required: q.isRequired,
				})),
			})),
		};

		console.log("📩 Sending Form Data:", JSON.stringify(formData, null, 2));

		await CreateForm(formData);
		message.success("Form saved successfully!");
		navigate("/form");
	};

	const reorder = (list, startIndex, endIndex) => {
		if (!list || !Array.isArray(list)) return []; // Ensure list is valid
		const result = [...list];
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);
		return result;
	};

	const onDragEnd = (result) => {
		if (!result.destination) return;
	
		const { source, destination } = result;
	
		// 🟢 1️⃣ Handle standalone question reordering
		if (source.droppableId === "questions" && destination.droppableId === "questions") {
			setQuestions((prevQuestions) => reorder(prevQuestions, source.index, destination.index));
			return;
		}
	
		// 🟢 2️⃣ Handle Section reordering
		if (source.droppableId === "sections" && destination.droppableId === "sections") {
			setSections((prevSections) => reorder(prevSections, source.index, destination.index));
			return;
		}
	
		// 🟢 3️⃣ Handle moving questions within or between sections
		const sourceSectionIndex = parseInt(source.droppableId.split("-")[1], 10);
		const destinationSectionIndex = parseInt(destination.droppableId.split("-")[1], 10);
	
		// Ignore invalid drag
		if (isNaN(sourceSectionIndex) || isNaN(destinationSectionIndex)) return;
	
		const updatedSections = [...sections];
	
		if (sourceSectionIndex === destinationSectionIndex) {
			// Reordering within the same section
			updatedSections[sourceSectionIndex].questions = reorder(
				updatedSections[sourceSectionIndex].questions,
				source.index,
				destination.index
			);
		} else {
			// Moving question from one section to another
			const movedQuestion = updatedSections[sourceSectionIndex].questions[source.index];
			updatedSections[sourceSectionIndex].questions.splice(source.index, 1);
			updatedSections[destinationSectionIndex].questions.splice(destination.index, 0, movedQuestion);
		}
	
		setSections(updatedSections);
	};
	

	return (
		<Box className="form-builder">
			<Box
				display="flex"
				justifyContent="space-between"
				alignItems="center"
				marginBottom="20px"
			>
				<Tooltip title="Return to Forms">
					<Button
						shape="circle"
						icon={<ArrowLeftOutlined />}
						onClick={navigateToForm}
					/>
				</Tooltip>
				<div>
					<h3 className="page-title">Create New Form</h3>
				</div>
				<div>
					<Flex wrap gap="small">
						<Tooltip title="Add Questions">
							<Button shape="circle" icon={<FaPlus />} onClick={addQuestion} />
						</Tooltip>
						<Tooltip title="Add Section">
							<Button shape="circle" icon={<FiServer />} onClick={addSection} />
						</Tooltip>
					</Flex>
				</div>
			</Box>
			<Card className="form-header">
				<TextField
					label="Enter Form Title"
					fullWidth
					variant="standard"
					className="form-title"
					value={formTitle}
					onChange={(e) => setFormTitle(e.target.value)}
				/>
				<TextField
					label="Enter Form description"
					fullWidth
					variant="standard"
					className="form-description"
					value={formDescription}
					onChange={(e) => setFormDescription(e.target.value)}
				/>
				<TextField
					label="Enter Price(in CAD)"
					style={{marginTop:"24px"}}
					fullWidth
					variant="standard"
					type="number"
					value={formPrice}
					onChange={(e) => setFormPrice(e.target.value)}
					slotProps={{
						inputLabel: {
							shrink: true,
						},
					}}
				/>
			</Card>

			{/* 🔹 Standalone Questions */}
			<DragDropContext onDragEnd={onDragEnd}>
				{/* 🔹 Standalone Questions */}
				<Droppable droppableId="questions">
					{(provided) => (
						<div
							ref={provided.innerRef}
							{...provided.droppableProps}
							style={{ minHeight: "50px" }}
						>
							{questions.map((q, index) => (
								<Draggable
									key={`q-${index}`}
									draggableId={`q-${index}`}
									index={index}
								>
									{(provided) => (
										<Card
											ref={provided.innerRef}
											{...provided.draggableProps}
											{...provided.dragHandleProps}
											className="question-card"
										>
											<Accordion defaultExpanded>
												<AccordionSummary expandIcon={<ExpandMore />}>
													<Typography>
														<DragIndicator style={{ cursor: "grab" }} />{" "}
														Question {index + 1}
													</Typography>
												</AccordionSummary>
												<AccordionDetails>
													<TextField
														className="question-text"
														style={{
															backgroundColor: "#fbfcf8",
															marginBottom: "15px",
														}}
														fullWidth
														variant="standard"
														placeholder="Enter Your Question Here"
														value={q.questionText}
														onChange={(e) =>
															updateStandaloneQuestion(
																index,
																"questionText",
																e.target.value
															)
														}
													/>
													<Select
														fullWidth
														variant="standard"
														value={q.type}
														onChange={(e) =>
															updateStandaloneQuestion(
																index,
																"type",
																e.target.value
															)
														}
														className="question-type"
													>
														<MenuItem value="TEXT">Short Answer</MenuItem>
														<MenuItem value="PARAGRAPH">Paragraph</MenuItem>
														<MenuItem value="MULTIPLE_CHOICE">
															Multiple Choice
														</MenuItem>
														<MenuItem value="CHECKBOX">Checkboxes</MenuItem>
														<MenuItem value="DROPDOWN">Dropdown</MenuItem>
														<MenuItem value="LINEAR_SCALE">
															Rating (1-5)
														</MenuItem>
														<MenuItem value="FILE_UPLOAD">File Upload</MenuItem>
														<MenuItem value="DATE">Date</MenuItem>
														<MenuItem value="TIME">Time</MenuItem>
													</Select>
													{["MULTIPLE_CHOICE", "DROPDOWN", "CHECKBOX"].includes(
														q.type
													) && (
														<>
															{q.options.map((option, optIndex) => (
																<Box
																	key={optIndex}
																	className="option"
																	display="flex"
																	alignItems="center"
																>
																	<TextField
																		fullWidth
																		variant="standard"
																		placeholder={`Option ${optIndex + 1}`}
																		value={option}
																		onChange={(e) => {
																			let newOptions = [...q.options];
																			newOptions[optIndex] = e.target.value;
																			updateStandaloneQuestion(
																				index,
																				"options",
																				newOptions
																			);
																		}}
																	/>
																	<IconButton
																		onClick={() =>
																			updateStandaloneQuestion(
																				index,
																				"options",
																				q.options.filter(
																					(_, i) => i !== optIndex
																				)
																			)
																		}
																	>
																		<Delete />
																	</IconButton>
																</Box>
															))}
															<Button
																style={{ marginTop: "10px" }}
																startIcon={<Add />}
																onClick={() =>
																	updateStandaloneQuestion(index, "options", [
																		...q.options,
																		"",
																	])
																}
															>
																{" "}
																+ Add Option
															</Button>
														</>
													)}

													{q.type === "LINEAR_SCALE" && (
														<div>
															<TextField
																fullWidth
																variant="standard"
																placeholder="Min Value"
																type="number"
																value={q.scaleMin}
																onChange={(e) =>
																	updateStandaloneQuestion(
																		index,
																		"scaleMin",
																		e.target.value
																	)
																}
															/>
															<TextField
																fullWidth
																variant="standard"
																placeholder="Max Value"
																type="number"
																value={q.scaleMax}
																onChange={(e) =>
																	updateStandaloneQuestion(
																		index,
																		"scaleMax",
																		e.target.value
																	)
																}
															/>
															<TextField
																fullWidth
																variant="standard"
																placeholder="Low Label"
																value={q.lowLabel}
																onChange={(e) =>
																	updateStandaloneQuestion(
																		index,
																		"lowLabel",
																		e.target.value
																	)
																}
															/>
															<TextField
																fullWidth
																variant="standard"
																placeholder="High Label"
																value={q.highLabel}
																onChange={(e) =>
																	updateStandaloneQuestion(
																		index,
																		"highLabel",
																		e.target.value
																	)
																}
															/>
														</div>
													)}

													<div className="question_required_delete_buttons">
														{/* Required Toggle */}
														<FormControlLabel
															control={
																<Switch
																	checked={q.isRequired}
																	onChange={(e) =>
																		updateStandaloneQuestion(
																			index,
																			"isRequired",
																			e.target.checked
																		)
																	}
																/>
															}
															label="Required"
														/>

														{/* Delete Question Button */}
														<IconButton
															onClick={() => deleteQuestion(index)}
															className="delete-question"
														>
															<Delete />
														</IconButton>
													</div>
												</AccordionDetails>
											</Accordion>
										</Card>
									)}
								</Draggable>
							))}
							{provided.placeholder}
						</div>
					)}
				</Droppable>

				{/* 🔹 Sections */}
				<Droppable droppableId="sections" type="section">
					{(provided) => (
						<div ref={provided.innerRef} {...provided.droppableProps}>
							{sections.map((section, sectionIndex) => (
								<Draggable
									key={`section-${sectionIndex}`}
									draggableId={`section-${sectionIndex}`}
									index={sectionIndex}
								>
									{(provided) => (
										<Card
											ref={provided.innerRef}
											{...provided.draggableProps}
											{...provided.dragHandleProps}
											className="section-card"
										>
											{/* Section Title */}
											

											<div
												style={{
													display: "flex",
													flexDirection: "row",
													justifyContent: "space-between",
													padding: "10px",
												}}
											>
												<DragIndicator style={{ marginTop: "5px" }} />
												<TextField
													fullWidth
													variant="standard"
													placeholder="Section Title"
													value={section.title}
													onChange={(e) => {
														const updated = [...sections];
														updated[sectionIndex].title = e.target.value;
														setSections(updated);
													}}
												/>
												{/* Add/Delete Section Buttons */}
												<Flex wrap gap="small">
													<Tooltip title="Delete Section">
														<Button
															shape="circle"
															icon={<DeleteOutlined />}
															onClick={() => deleteSection(sectionIndex)}
														/>
													</Tooltip>
													<Tooltip title="Add Question">
														<Button
															shape="circle"
															icon={<FaPlus />}
															onClick={() => addQuestionToSection(sectionIndex)}
														/>
													</Tooltip>
												</Flex>
											</div>

											{/* Questions List */}
											<Droppable
												droppableId={`section-${sectionIndex}`}
												type="question"
											>
												{(provided) => (
													<div
														ref={provided.innerRef}
														{...provided.droppableProps}
													>
														{section.questions.map((q, questionIndex) => (
															<Draggable
																key={`sec-q-${sectionIndex}-${questionIndex}`}
																draggableId={`sec-q-${sectionIndex}-${questionIndex}`}
																index={questionIndex}
															>
																{(provided) => (
																	<Accordion
																		ref={provided.innerRef}
																		{...provided.draggableProps}
																		{...provided.dragHandleProps}
																		className="question-accordion"
																	>
																		<AccordionSummary
																			expandIcon={<ExpandMore />}
																		>
																			<Typography>
																				Question {questionIndex + 1}
																			</Typography>
																		</AccordionSummary>
																		<AccordionDetails>
																			{/* Question Input */}
																			<TextField
																				style={{
																					backgroundColor: "#fbfcf8",
																					marginBottom: "15px",
																				}}
																				fullWidth
																				variant="standard"
																				placeholder="Enter Your Question Here"
																				value={q.questionText}
																				onChange={(e) =>
																					updateSectionQuestion(
																						sectionIndex,
																						questionIndex,
																						"questionText",
																						e.target.value
																					)
																				}
																			/>

																			{/* Question Type Selection */}
																			<Select
																				fullWidth
																				variant="standard"
																				value={q.type}
																				onChange={(e) =>
																					updateSectionQuestion(
																						sectionIndex,
																						questionIndex,
																						"type",
																						e.target.value
																					)
																				}
																			>
																				<MenuItem value="TEXT">
																					Short Answer
																				</MenuItem>
																				<MenuItem value="PARAGRAPH">
																					Paragraph
																				</MenuItem>
																				<MenuItem value="MULTIPLE_CHOICE">
																					Multiple Choice
																				</MenuItem>
																				<MenuItem value="CHECKBOX">
																					Checkboxes
																				</MenuItem>
																				<MenuItem value="DROPDOWN">
																					Dropdown
																				</MenuItem>
																				<MenuItem value="LINEAR_SCALE">
																					Rating (1-5)
																				</MenuItem>
																				<MenuItem value="FILE_UPLOAD">
																					File Upload
																				</MenuItem>
																				<MenuItem value="DATE">Date</MenuItem>
																				<MenuItem value="TIME">Time</MenuItem>
																			</Select>

																			{/* Options for Multiple Choice, Dropdown, Checkboxes */}
																			{[
																				"MULTIPLE_CHOICE",
																				"CHECKBOX",
																				"DROPDOWN",
																			].includes(q.type) && (
																				<div>
																					{q.options.map((option, optIndex) => (
																						<Box
																							key={optIndex}
																							display="flex"
																							alignItems="center"
																						>
																							<TextField
																								fullWidth
																								variant="standard"
																								placeholder={`Option ${
																									optIndex + 1
																								}`}
																								value={option}
																								onChange={(e) => {
																									let newOptions = [
																										...q.options,
																									];
																									newOptions[optIndex] =
																										e.target.value;
																									updateSectionQuestion(
																										sectionIndex,
																										questionIndex,
																										"options",
																										newOptions
																									);
																								}}
																							/>
																							<IconButton
																								onClick={() =>
																									deleteOption(
																										sectionIndex,
																										questionIndex,
																										optIndex
																									)
																								}
																							>
																								<Delete />
																							</IconButton>
																						</Box>
																					))}
																					<Button
																						onClick={() =>
																							updateSectionQuestion(
																								sectionIndex,
																								questionIndex,
																								"options",
																								[...q.options, ""]
																							)
																						}
																					>
																						+ Add Option
																					</Button>
																				</div>
																			)}

																			{/* Linear Scale Input */}
																			{q.type === "LINEAR_SCALE" && (
																				<div>
																					<TextField
																						fullWidth
																						variant="standard"
																						placeholder="Min Value"
																						type="number"
																						value={q.scaleMin}
																						onChange={(e) =>
																							updateSectionQuestion(
																								sectionIndex,
																								questionIndex,
																								"scaleMin",
																								e.target.value
																							)
																						}
																					/>
																					<TextField
																						fullWidth
																						variant="standard"
																						placeholder="Max Value"
																						type="number"
																						value={q.scaleMax}
																						onChange={(e) =>
																							updateSectionQuestion(
																								sectionIndex,
																								questionIndex,
																								"scaleMax",
																								e.target.value
																							)
																						}
																					/>
																					<TextField
																						fullWidth
																						variant="standard"
																						placeholder="Low Label"
																						value={q.lowLabel}
																						onChange={(e) =>
																							updateSectionQuestion(
																								sectionIndex,
																								questionIndex,
																								"lowLabel",
																								e.target.value
																							)
																						}
																					/>
																					<TextField
																						fullWidth
																						variant="standard"
																						placeholder="High Label"
																						value={q.highLabel}
																						onChange={(e) =>
																							updateSectionQuestion(
																								sectionIndex,
																								questionIndex,
																								"highLabel",
																								e.target.value
																							)
																						}
																					/>
																				</div>
																			)}

																			{/* Question Actions */}
																			<div className="question_required_delete_buttons">
																				<FormControlLabel
																					control={
																						<Switch
																							checked={q.isRequired}
																							onChange={(e) =>
																								updateSectionQuestion(
																									sectionIndex,
																									questionIndex,
																									"isRequired",
																									e.target.checked
																								)
																							}
																						/>
																					}
																					label="Required"
																				/>

																				<IconButton
																					onClick={() =>
																						deleteSectionQuestion(
																							sectionIndex,
																							questionIndex
																						)
																					}
																				>
																					<Delete />
																				</IconButton>
																			</div>
																		</AccordionDetails>
																	</Accordion>
																)}
															</Draggable>
														))}
														{provided.placeholder}
													</div>
												)}
											</Droppable>
										</Card>
									)}
								</Draggable>
							))}
							{provided.placeholder}
						</div>
					)}
				</Droppable>
			</DragDropContext>

			{/* 🔹 Submit Form */}
			<Button
				type="primary"
				size="large"
				onClick={createForm}
				className="submit-btn"
			>
				Create Form
			</Button>
		</Box>
	);
};

export default FormBuilder;
