/** @format */

import React, { useState } from "react";
import { Box } from "@mui/material";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Checkbox, message, Form, Input } from "antd";
import { TiInfoOutline } from "react-icons/ti";
import { CreateAdmin } from "../../services/Api/Api";
import { useNavigate } from "react-router-dom";

const roleData = {
	owner: { id: 1, children: [] },
	admin: { id: 2, children: [] },
	accessManagement: { id: 3, children: [] },
	userManagement: {
		id: 4,
		children: ["individualUserManagement", "corporateUserManagement"],
	},
	individualUserManagement: { id: 1, parent: "userManagement" },
	corporateUserManagement: { id: 2, parent: "userManagement" },
	paymentManagement: { id: 5, children: [] },
	courseManagement: { id: 9, children: ["course", "test"] },
	course: { id: 3, parent: "courseManagement" },
	test: { id: 4, parent: "courseManagement" },
	contentManagement: { id: 10, children: ["forumManagement", "contactUsForms"] },
	forumManagement: { id: 5, parent: "contentManagement" },
	contactUsForms: { id: 6, parent: "contentManagement" },
	dashboardManagement: { id: 11, children: [] },
	formManagement: { id: 12, children: [] },
	// formPurchasesManagement: { id: 13, children: [] },
};

function PermissionManagement() {
	const navigate = useNavigate();
	const [form] = Form.useForm();
	const [checkedOptions, setCheckedOptions] = useState({
		owner: false,
		admin: false,
		accessManagement: false,
		userManagement: false,
		individualUserManagement: false,
		corporateUserManagement: false,
		paymentManagement: false,
		courseManagement: false,
		course: false,
		test: false,
		contentManagement: false,
		forumManagement: false,
		contactUsForms: false,
		dashboardManagement: false,
		formManagement: false,
	});

	const handleParentChange = (parent, children = []) => {
		setCheckedOptions((prevState) => {
			const parentChecked = !prevState[parent];
			const newState = { ...prevState, [parent]: parentChecked };
			children.forEach((child) => {
				newState[child] = parentChecked;
			});
			return newState;
		});
	};

	const handleChildChange = (child) => {
		setCheckedOptions((prevState) => {
			const newState = { ...prevState, [child]: !prevState[child] };
	
			// Check if all children of a parent are selected
			Object.keys(roleData).forEach((key) => {
				if (roleData[key].children && roleData[key].children.includes(child)) {
					// If all children are selected, select the parent checkbox
					const allChildrenSelected = roleData[key].children.every(
						(childKey) => newState[childKey] === true
					);
					newState[key] = allChildrenSelected;
				}
			});
	
			return newState;
		});
	};
	

	const handleOwnerChange = () => {
		setCheckedOptions((prevState) => {
			const ownerChecked = !prevState.owner;
			const newState = Object.keys(prevState).reduce((acc, key) => {
				acc[key] = ownerChecked;
				return acc;
			}, {});
			return newState;
		});
	};

	const handleAdminChange = () => {
		setCheckedOptions((prevState) => {
			const adminChecked = !prevState.admin;
			const newState = Object.keys(prevState).reduce((acc, key) => {
				acc[key] = key === "owner" ? false : adminChecked;
				return acc;
			}, {});
			return newState;
		});
	};

	const handleSubmit = async () => {
		const roleIds = [];
		const subRoleIds = [];
	
		// Collect parent and child role IDs
		Object.keys(checkedOptions).forEach((key) => {
			if (checkedOptions[key]) {
				if (roleData[key].children) {
					roleIds.push(roleData[key].id); // Parent roles
				} else if (roleData[key].parent) {
					subRoleIds.push(roleData[key].id); // Child roles
				}
			}
		});
	
		const payload = {
			name: form.getFieldValue("name"),
			email: form.getFieldValue("email"),
			role_id: roleIds,
			sub_role_id: subRoleIds,
		};
	
		if (!payload.name || !payload.email || (roleIds.length === 0 && subRoleIds.length === 0)) {
			message.error("Please fill all required fields and select a role");
			return;
		}
	
		try {
			const response = await CreateAdmin(payload);
	
			// Check if the email already exists based on the API response
			if (response.status === 400 && response.message === "Email already exists") {
				message.error("This email is already registered. Please use a different email.");
				return;
			}
	
			if (response.status === 201) {
				message.success("Admin added successfully");
				setTimeout(() => navigate("/adminList"), 1000);
			}
		} catch (error) {
			message.error(error.response?.data?.message || "Something went wrong");
		}
	};
	

	const navigateToAdmin = () => {
		navigate("/adminList");
	};

	return (
		<Box>
			<Box display="flex" justifyContent="space-between" marginBottom="20px">
				<div>
					<h3 className="page-title">Admin Management</h3>
					<p className="page-sub-title">Create New Admin</p>
				</div>
				<Button
					icon="pi pi-arrow-left"
					severity="secondary"
					onClick={() => navigate("/adminList")}
					style={{ borderRadius: "5px", height: "47px" }}
				>
					Return to Admin List
				</Button>
			</Box>

			<div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
				<Card style={{ width: "70%" }}>
					<Form layout="vertical" form={form} onFinish={handleSubmit}>
						<Form.Item
							label="Name"
							name="name"
							rules={[{ required: true, message: "Please enter a name" }]}
						>
							<Input placeholder="Enter name" />
						</Form.Item>

						<Form.Item
							label="Email"
							name="email"
							rules={[{ required: true, message: "Please enter an email" }]}
						>
							<Input type="email" placeholder="Enter email" />
						</Form.Item>

						<Form.Item
							label="Select Roles"
							name="roles"
							// rules={[{ required: true}]}
						>
							<div>
								<Checkbox
									checked={checkedOptions.owner}
									onChange={handleOwnerChange}
								>
									Owner
								</Checkbox>
							</div>
							<div>
								<Checkbox
									checked={checkedOptions.admin}
									onChange={handleAdminChange}
								>
									Admin
								</Checkbox>
							</div>
							<div>
								<Checkbox
									checked={checkedOptions.accessManagement}
									onChange={() => handleChildChange("accessManagement")}
								>
									Access Management
								</Checkbox>
							</div>
							<div>
								<Checkbox
									checked={checkedOptions.paymentManagement}
									onChange={() => handleChildChange("paymentManagement")}
								>
									Payment Management
								</Checkbox>
							</div>
							<div>
								<Checkbox
									checked={checkedOptions.userManagement}
									onChange={() =>
										handleParentChange("userManagement", [
											"individualUserManagement",
											"corporateUserManagement",
										])
									}
								>
									User Management
								</Checkbox>
								<div style={{ paddingLeft: "20px" }}>
									<Checkbox
										checked={checkedOptions.individualUserManagement}
										onChange={() =>
											handleChildChange("individualUserManagement")
										}
									>
										Individual User Management
									</Checkbox>
									<Checkbox
										style={{ marginLeft: "10px" }}
										checked={checkedOptions.corporateUserManagement}
										onChange={() =>
											handleChildChange("corporateUserManagement")
										}
									>
										Corporate User Management
									</Checkbox>
								</div>
							</div>

							<div>
								<Checkbox
									checked={checkedOptions.courseManagement}
									onChange={() =>
										handleParentChange("courseManagement", ["course", "test"])
									}
								>
									Course Management
								</Checkbox>
								<div style={{ paddingLeft: "20px" }}>
									<Checkbox
										checked={checkedOptions.course}
										onChange={() => handleChildChange("course")}
									>
										Course
									</Checkbox>
									<Checkbox
										checked={checkedOptions.test}
										onChange={() => handleChildChange("test")}
									>
										Test
									</Checkbox>
								</div>
							</div>
							<div>
								<Checkbox
									checked={checkedOptions.contentManagement}
									onChange={() =>
										handleParentChange("contentManagement", [
											"forumManagement",
											"contactUsForms",
										])
									}
								>
									Content Management
								</Checkbox>
								<div style={{ paddingLeft: "20px" }}>
									<Checkbox
										checked={checkedOptions.forumManagement}
										onChange={() => handleChildChange("forumManagement")}
									>
										Forum Management
									</Checkbox>
									<Checkbox
										checked={checkedOptions.contactUsForms}
										onChange={() => handleChildChange("contactUsForms")}
									>
										Contact Us Forms
									</Checkbox>
								</div>
							</div>
							{/* <div>
								<Checkbox
									checked={checkedOptions.formPurchasesManagement}
									onChange={() => handleChildChange("formPurchasesManagement")}
								>
									Form Purchases Management 
								</Checkbox>
							</div> */}
							<div>
								<Checkbox
									checked={checkedOptions.formManagement}
									onChange={() => handleChildChange("formManagement")}
								>
									Form Management
								</Checkbox>
							</div>
							<div>
								<Checkbox
									checked={checkedOptions.dashboardManagement}
									onChange={() => handleChildChange("dashboardManagement")}
								>
									Dashboard Management
								</Checkbox>
							</div>
						</Form.Item>

						<div style={{ marginTop: "50px" }}>
							<Button
								icon="pi pi-check"
								severity="info"
								htmlType="submit"
								type="primary"
			
								style={{
									borderRadius: "5px",
									margin: "0px 0px",
									height: "40px",
								}}
							>
								Save
							</Button>

							<Button
								icon="pi pi-times"
								severity="secondary"
								onClick={(e) => {
									navigateToAdmin();
								}}
								style={{
									borderRadius: "5px",
									marginLeft: "10px",
									height: "40px",
								}}
							>
								Cancel
							</Button>
						</div>
					</Form>
				</Card>

				<Card
					className="admin_description"
					style={{ width: "30%", marginLeft: "10px" }}
				>
					<div
						style={{
							display: "flex",
							alignItems: "center",
							marginBottom: "15px",
						}}
					>
						<TiInfoOutline
							style={{
								fontSize: "24px",
								color: "red",
								marginRight: "10px",
							}}
						/>
						<h4 style={{ margin: 0 }}>Important Note:</h4>
					</div>
					<p style={{ fontSize: "14px", color: "#555" }}>
						You can assign access to specific pages for this new admin. The
						admin will only be able to access the pages you assign, and all
						other pages will remain inaccessible.
					</p>
					<div style={{ paddingLeft: "30px", fontSize: "14px", color: "#555" }}>
						<ul style={{ listStyleType: "disc" }}>
							<li>
								Assign page access based on the admin's role and
								responsibilities.
							</li>
							<li>
								Admins with restricted access can only manage specific sections.
							</li>
							<li>
								For example, an admin can be given access to User but not to
								Course or Admin.
							</li>
						</ul>
					</div>
				</Card>
			</div>
		</Box>
	);
}

export default PermissionManagement;
