/** @format */

import React, { useEffect, useState } from "react";
import {
	Box,
	TextField,
	Typography,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Switch,
	Select,
	MenuItem,
	IconButton,
	Card,
	FormControlLabel,
} from "@mui/material";
import {
	ExpandMore,
	Add,
	Delete,
	ArrowLeftOutlined,
} from "@mui/icons-material";
import { useNavigate, useParams } from "react-router";
import { message, Button, Tooltip, Flex } from "antd";
import { FaPlus } from "react-icons/fa";
import { FiServer } from "react-icons/fi";
import { DeleteOutlined } from "@ant-design/icons";
import { GetFormById, UpdateForm } from "../../services/Api/FormApi";

const EditFormBuilder = () => {
	const navigate = useNavigate();
	const { formId } = useParams(); // Get the form ID from the URL params

	const [formTitle, setFormTitle] = useState("");
	const [formDescription, setFormDescription] = useState("");
	const [formPrice, setFormPrice] = useState("");
	const [sections, setSections] = useState([]);
	const [questions, setQuestions] = useState([]);

	// ** Fetch Form Details **
	useEffect(() => {
		const fetchFormDetails = async () => {
			try {
				const response = await GetFormById(formId);
				const formData = response?.data?.data;
				console.log(response.data.data, "response");

				setFormTitle(formData.name);
				setFormDescription(formData.description);
				setFormPrice(formData.price);
				setQuestions(formData.form_questions || []);
				setSections(formData.form_section || []);
			} catch (error) {
				console.error("Error fetching form details:", error);
				message.error("Failed to fetch form details.");
			}
		};
		fetchFormDetails();
	}, [formId]);

	// ** Update Question Fields **
	const updateStandaloneQuestion = (index, field, value) => {
		setQuestions((prevQuestions) => {
			const updatedQuestions = [...prevQuestions];
			const question = updatedQuestions[index];
	
			// Correct field mapping for Linear Scale questions
			const fieldMapping = {
				scale_min: "scaleMin",
				scale_max: "scaleMax",
				low_label: "lowLabel",
				high_label: "highLabel",
			};
	
			// Convert min/max values to numbers
			if (field === "scale_min" || field === "scale_max") {
				question[field] = value !== "" ? Number(value) : null;
			} else {
				question[field] = value !== "" ? value : null;
			}
	
			return updatedQuestions;
		});
	};
	
	const updateSectionQuestion = (sectionIndex, questionIndex, field, value) => {
		setSections((prevSections) => {
			const updatedSections = [...prevSections];
			const question =
				updatedSections[sectionIndex].section_questions[questionIndex];

			// Ensure correct field mappings for Linear Scale type
			const fieldMapping = {
				scaleMin: "scale_min",
				scaleMax: "scale_max",
				lowLabel: "low_label",
				highLabel: "high_label",
			};

			if (question.type === "LINEAR_SCALE") {
				question[fieldMapping[field] || field] =
					value !== "" ? Number(value) : null;
			} else if (
				["MULTIPLE_CHOICE", "CHECKBOX", "DROPDOWN"].includes(question.type)
			) {
				question.options = Array.isArray(question.options)
					? question.options
					: [];
			} else {
				question[field] = value !== "" ? value : null;
			}

			return updatedSections;
		});
	};

	const addQuestion = () => {
		setQuestions((prevQuestions) => [
			...prevQuestions,
			{
				id: undefined, // New question does not have an ID initially
				question_text: "",
				type: "TEXT",
				options: [],
				is_required: false,
				scale_min: null,
				scale_max: null,
				low_label: "",
				high_label: "",
			},
		]);
	};

	const addSection = () => {
		setSections((prevSections) => [
			...prevSections,
			{
				id: undefined, // New section does not have an ID initially
				title: "",
				section_questions: [
					{
						id: undefined,
						question_text: "",
						type: "TEXT",
						options: [],
						is_required: false,
						scale_min: null,
						scale_max: null,
						low_label: "",
						high_label: "",
					},
				],
			},
		]);
	};

	const deleteQuestion = (index) => {
		const updatedQuestions = [...questions];
		updatedQuestions.splice(index, 1);
		setQuestions(updatedQuestions);
	};

	const deleteSection = (sectionIndex) => {
		const updatedSections = [...sections];
		updatedSections.splice(sectionIndex, 1);
		setSections(updatedSections);
	};

	const addQuestionToSection = (sectionIndex) => {
		const updatedSections = [...sections];
		updatedSections[sectionIndex].section_questions.push({
			question_text: "",
			type: "TEXT",
			options: [],
			is_required: false,
			scaleMin: null,
			scaleMax: null,
			lowLabel: "",
			highLabel: "",
		});
		setSections(updatedSections);
	};

	const deleteQuestionFromSection = (sectionIndex, questionIndex) => {
		const updatedSections = [...sections];
		updatedSections[sectionIndex].section_questions.splice(questionIndex, 1);
		setSections(updatedSections);
	};

	const updateSection = (index, field, value) => {
		const updatedSections = [...sections];
		updatedSections[index][field] = value;
		setSections(updatedSections);
	};

	// ** Submit Updated Form Data **
	const updateForm = async () => {
		const updatedFormData = {
			id: formId,
			name: formTitle,
			description: formDescription,
			price: formPrice,
			general_questions: questions.map((q) => ({
				id: q.id || undefined,
				question_text: q.question_text,
				type: q.type,
				options:
					q.type === "MULTIPLE_CHOICE" || q.type === "CHECKBOX" || q.type === "DROPDOWN"
						? Array.isArray(q.options)
							? q.options
							: q.options
							? JSON.parse(q.options)
							: []
						: null,
				scaleMin: q.type === "LINEAR_SCALE" ? q.scale_min ?? null : null, 
				scaleMax: q.type === "LINEAR_SCALE" ? q.scale_max ?? null : null, 
				lowLabel: q.type === "LINEAR_SCALE" ? q.low_label ?? "" : null, 
				highLabel: q.type === "LINEAR_SCALE" ? q.high_label ?? "" : null, 
				is_required: q.is_required,
			})),
			sections: sections.map((section) => ({
				id: section.id || undefined,
				title: section.title,
				questions: section.section_questions.map((q) => ({
					id: q.id || undefined,
					question_text: q.question_text,
					type: q.type,
					options:
						q.type === "MULTIPLE_CHOICE" || q.type === "CHECKBOX" || q.type === "DROPDOWN"
							? Array.isArray(q.options)
								? q.options
								: q.options
								? JSON.parse(q.options)
								: []
							: null,
					scaleMin: q.type === "LINEAR_SCALE" ? q.scale_min ?? null : null, 
					scaleMax: q.type === "LINEAR_SCALE" ? q.scale_max ?? null : null, 
					lowLabel: q.type === "LINEAR_SCALE" ? q.low_label ?? "" : null, 
					highLabel: q.type === "LINEAR_SCALE" ? q.high_label ?? "" : null, 
					is_required: q.is_required,
				})),
			})),
		};
	
		try {
			await UpdateForm(formId, updatedFormData);
			message.success("Form updated successfully!");
			navigate("/form");
		} catch (error) {
			console.error("Error updating form:", error);
			message.error("Failed to update form.");
		}
	};
	

	const navigateToForm = () => {
		navigate("/form");
	};

	return (
		<Box className="form-builder">
			<Box
				display="flex"
				justifyContent="space-between"
				alignItems="center"
				marginBottom="20px"
			>
				<div>
					<h3 className="page-title">Update Form</h3>
				</div>
				<div>
					<Flex wrap gap="small">
						<Tooltip title="Return to Forms">
							<Button
								shape="circle"
								icon={<ArrowLeftOutlined />}
								onClick={navigateToForm}
							/>
						</Tooltip>
						<Tooltip title="Add Questions">
							<Button shape="circle" icon={<FaPlus />} onClick={addQuestion} />
						</Tooltip>
						<Tooltip title="Add Section">
							<Button shape="circle" icon={<FiServer />} onClick={addSection} />
						</Tooltip>
					</Flex>
				</div>
			</Box>

			<Card className="form-header">
				<TextField
					label="Form Title"
					fullWidth
					variant="standard"
					value={formTitle}
					onChange={(e) => {
						setFormTitle(e.target.value);
					}}
				/>

				<TextField
					label="Form Description"
					fullWidth
					variant="standard"
					value={formDescription}
					onChange={(e) => {
						setFormDescription(e.target.value);
					}}
				/>

				<TextField
					label="Form Price"
					fullWidth
					variant="standard"
					type="number"
					value={formPrice}
					onChange={(e) => {
						setFormPrice(e.target.value);
					}}
				/>
			</Card>

			{/* 🔹 General Questions */}
			{questions.map((q, index) => (
				<Card key={index} className="question-card">
					<Accordion defaultExpanded>
						<AccordionSummary>
							<Typography>Question {index + 1}</Typography>
						</AccordionSummary>
						<AccordionDetails>
							{/* Question Text */}
							<TextField
								style={{
									backgroundColor: "#fbfcf8",
									marginBottom: "10px",
								}}
								fullWidth
								variant="standard"
								placeholder="Enter Your Question Here"
								// label={`Question ${index + 1}`}
								value={q.question_text}
								onChange={(e) =>
									updateStandaloneQuestion(
										index,
										"question_text",
										e.target.value
									)
								}
							/>

							{/* Question Type Selector */}
							<Select
								fullWidth
								variant="standard"
								value={q.type}
								onChange={(e) => {
									const newType = e.target.value;
									updateStandaloneQuestion(index, "type", newType);

									// If switching to a type that requires options, initialize empty options
									if (
										["MULTIPLE_CHOICE", "CHECKBOX", "DROPDOWN"].includes(
											newType
										)
									) {
										updateStandaloneQuestion(
											index,
											"options",
											q.options || [""]
										);
									} else {
										updateStandaloneQuestion(index, "options", null);
									}
								}}
							>
								<MenuItem value="TEXT">Short Answer</MenuItem>
								<MenuItem value="PARAGRAPH">Paragraph</MenuItem>
								<MenuItem value="MULTIPLE_CHOICE">Multiple Choice</MenuItem>
								<MenuItem value="CHECKBOX">Checkboxes</MenuItem>
								<MenuItem value="DROPDOWN">Dropdown</MenuItem>
								<MenuItem value="LINEAR_SCALE">Rating (1-5)</MenuItem>
								<MenuItem value="FILE_UPLOAD">File Upload</MenuItem>
								<MenuItem value="DATE">Date</MenuItem>
								<MenuItem value="TIME">Time</MenuItem>
							</Select>

							{/* Options for Multiple Choice, Checkbox, Dropdown */}
							{["MULTIPLE_CHOICE", "CHECKBOX", "DROPDOWN"].includes(q.type) && (
								<>
									{(Array.isArray(q.options)
										? q.options
										: JSON.parse(q.options || "[]")
									)?.map((option, optIndex) => (
										<Box
											key={optIndex}
											display="flex"
											alignItems="center"
											gap="10px"
											mt={2}
										>
											<TextField
												fullWidth
												variant="standard"
												placeholder={`Option ${optIndex + 1}`}
												value={option}
												onChange={(e) => {
													const newOptions = [
														...(Array.isArray(q.options)
															? q.options
															: JSON.parse(q.options || "[]")),
													];
													newOptions[optIndex] = e.target.value;
													updateStandaloneQuestion(
														index,
														"options",
														newOptions
													);
												}}
											/>
											<IconButton
												onClick={() => {
													const newOptions = (
														Array.isArray(q.options)
															? q.options
															: JSON.parse(q.options || "[]")
													).filter((_, i) => i !== optIndex);
													updateStandaloneQuestion(
														index,
														"options",
														newOptions
													);
												}}
											>
												<Delete />
											</IconButton>
										</Box>
									))}
									<Button
										startIcon={<Add />}
										style={{ marginTop: "10px" }}
										onClick={() =>
											updateStandaloneQuestion(index, "options", [
												...(Array.isArray(q.options)
													? q.options
													: JSON.parse(q.options || "[]")),
												"",
											])
										}
									>
										+ Add Option
									</Button>
								</>
							)}

							{/* Linear Scale Inputs */}
							{q.type === "LINEAR_SCALE" && (
	<div>
		<TextField
			fullWidth
			variant="standard"
			label="Min Value"
			type="number"
			value={q.scale_min ?? ""}
			onChange={(e) =>
				updateStandaloneQuestion(index, "scale_min", e.target.value)
			}
		/>
		<TextField
			fullWidth
			variant="standard"
			label="Max Value"
			type="number"
			value={q.scale_max ?? ""}
			onChange={(e) =>
				updateStandaloneQuestion(index, "scale_max", e.target.value)
			}
		/>
		<TextField
			fullWidth
			variant="standard"
			label="Low Label"
			value={q.low_label ?? ""}
			onChange={(e) =>
				updateStandaloneQuestion(index, "low_label", e.target.value)
			}
		/>
		<TextField
			fullWidth
			variant="standard"
			label="High Label"
			value={q.high_label ?? ""}
			onChange={(e) =>
				updateStandaloneQuestion(index, "high_label", e.target.value)
			}
		/>
	</div>
)}

						</AccordionDetails>

						{/* Required Toggle & Delete Button */}
						<div className="question_required_delete_buttons">
							<FormControlLabel
								control={
									<Switch
										checked={q.is_required}
										onChange={(e) =>
											updateStandaloneQuestion(
												index,
												"is_required",
												e.target.checked
											)
										}
									/>
								}
								label="Required"
							/>

							<IconButton
								onClick={() => deleteQuestion(index)}
								className="delete-question"
							>
								<Delete />
							</IconButton>
						</div>
					</Accordion>
				</Card>
			))}

			{/* 🔹 Sections */}
			{sections.map((section, sectionIndex) => (
				<Card key={sectionIndex} className="section-card">
					<Typography
						variant="h6"
						style={{
							color: "#673AB7",
							fontSize: "16px",
							fontWeight: "bold",
							marginBottom: "8px",
							padding: "10px",
						}}
					>
						{`Section ${sectionIndex + 1} `}
					</Typography>
					{/* Section Header with Delete & Add Question Buttons */}
					<Box
						display="flex"
						justifyContent="space-between"
						alignItems="center"
					>
						<TextField
							fullWidth
							variant="standard"
							placeholder="Section Title"
							value={section.title}
							onChange={(e) =>
								updateSection(sectionIndex, "title", e.target.value)
							}
							style={{
								backgroundColor: "#fbfcf8",
								marginBottom: "10px",
								padding: "10px",
							}}
						/>
						<Flex wrap gap="small">
							<Tooltip title="Delete Section">
								<Button
									shape="circle"
									icon={<DeleteOutlined />}
									onClick={() => deleteSection(sectionIndex)}
								/>
							</Tooltip>
							<Tooltip title="Add Question">
								<Button
									shape="circle"
									icon={<FaPlus />}
									onClick={() => addQuestionToSection(sectionIndex)}
								/>
							</Tooltip>
						</Flex>
					</Box>

					{/* Section Questions */}
					{section.section_questions.map((q, questionIndex) => (
						<Accordion key={questionIndex} defaultExpanded>
							<AccordionSummary expandIcon={<ExpandMore />}>
								<Typography>Question {questionIndex + 1}</Typography>
							</AccordionSummary>
							<AccordionDetails>
								{/* Question Input */}
								<TextField
									fullWidth
									variant="standard"
									placeholder="Enter Your Question Here"
									style={{
										backgroundColor: "#fbfcf8",
										marginBottom: "10px",
									}}
									value={q.question_text}
									onChange={(e) =>
										updateSectionQuestion(
											sectionIndex,
											questionIndex,
											"question_text",
											e.target.value
										)
									}
								/>

								{/* Question Type Selector */}
								<Select
									fullWidth
									variant="standard"
									value={q.type}
									onChange={(e) =>
										updateSectionQuestion(
											sectionIndex,
											questionIndex,
											"type",
											e.target.value
										)
									}
								>
									<MenuItem value="TEXT">Short Answer</MenuItem>
									<MenuItem value="PARAGRAPH">Paragraph</MenuItem>
									<MenuItem value="MULTIPLE_CHOICE">Multiple Choice</MenuItem>
									<MenuItem value="CHECKBOX">Checkboxes</MenuItem>
									<MenuItem value="DROPDOWN">Dropdown</MenuItem>
									<MenuItem value="LINEAR_SCALE">Rating (1-5)</MenuItem>
									<MenuItem value="FILE_UPLOAD">File Upload</MenuItem>
									<MenuItem value="DATE">Date</MenuItem>
									<MenuItem value="TIME">Time</MenuItem>
								</Select>

								{/* Options for Multiple Choice, Checkbox, Dropdown */}
								{["MULTIPLE_CHOICE", "CHECKBOX", "DROPDOWN"].includes(
									q.type
								) && (
									<>
										{(Array.isArray(q.options)
											? q.options
											: JSON.parse(q.options || "[]")
										)?.map((option, optIndex) => (
											<Box
												key={optIndex}
												display="flex"
												alignItems="center"
												gap="10px"
												mt={2}
											>
												<TextField
													fullWidth
													variant="standard"
													placeholder={`Option ${optIndex + 1}`}
													value={option}
													onChange={(e) => {
														const newOptions = [
															...(Array.isArray(q.options)
																? q.options
																: JSON.parse(q.options || "[]")),
														];
														newOptions[optIndex] = e.target.value;
														updateSectionQuestion(
															sectionIndex,
															questionIndex,
															"options",
															newOptions
														);
													}}
												/>
												<IconButton
													onClick={() => {
														const newOptions = (
															Array.isArray(q.options)
																? q.options
																: JSON.parse(q.options || "[]")
														).filter((_, i) => i !== optIndex);
														updateSectionQuestion(
															sectionIndex,
															questionIndex,
															"options",
															newOptions
														);
													}}
												>
													<Delete />
												</IconButton>
											</Box>
										))}
										<Button
											style={{ marginTop: "10px" }}
											startIcon={<Add />}
											onClick={() =>
												updateSectionQuestion(
													sectionIndex,
													questionIndex,
													"options",
													[
														...(Array.isArray(q.options)
															? q.options
															: JSON.parse(q.options || "[]")),
														"",
													]
												)
											}
										>
											+ Add Option
										</Button>
									</>
								)}

								{/* Linear Scale Inputs */}
								{q.type === "LINEAR_SCALE" && (
									<div>
										<TextField
											fullWidth
											variant="standard"
											label="Min Value"
											type="number"
											value={q.scale_min ?? ""}
											onChange={(e) =>
												updateSectionQuestion(
													sectionIndex,
													questionIndex,
													"scale_min",
													e.target.value
												)
											}
										/>
										<TextField
											fullWidth
											variant="standard"
											label="Max Value"
											type="number"
											value={q.scale_max ?? ""}
											onChange={(e) =>
												updateSectionQuestion(
													sectionIndex,
													questionIndex,
													"scale_max",
													e.target.value
												)
											}
										/>
										<TextField
											fullWidth
											variant="standard"
											label="Low Label"
											value={q.low_label ?? ""}
											onChange={(e) =>
												updateSectionQuestion(
													sectionIndex,
													questionIndex,
													"low_label",
													e.target.value
												)
											}
										/>
										<TextField
											fullWidth
											variant="standard"
											label="High Label"
											value={q.high_label ?? ""}
											onChange={(e) =>
												updateSectionQuestion(
													sectionIndex,
													questionIndex,
													"high_label",
													e.target.value
												)
											}
										/>
									</div>
								)}
							</AccordionDetails>

							{/* Required Toggle & Delete Question Button */}
							<div className="question_required_delete_buttons">
								<FormControlLabel
									control={
										<Switch
											checked={q.is_required}
											onChange={(e) =>
												updateSectionQuestion(
													sectionIndex,
													questionIndex,
													"is_required",
													e.target.checked
												)
											}
										/>
									}
									label="Required"
								/>

								<IconButton
									onClick={() =>
										deleteQuestionFromSection(sectionIndex, questionIndex)
									}
									className="delete-question"
								>
									<Delete />
								</IconButton>
							</div>
						</Accordion>
					))}
				</Card>
			))}

			<Button
				type="primary"
				size="large"
				onClick={updateForm}
				className="submit-btn"
			>
				Update Form
			</Button>
		</Box>
	);
};

export default EditFormBuilder;
