import React, { useLayoutEffect, useState } from "react";
import { Box } from "@mui/material";
import { Form } from "react-bootstrap";
import { Checkbox, message } from "antd";
import { useNavigate, useParams } from "react-router";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { GetAdminById, UpdateAdmin } from "../../services/Api/Api.jsx";

const roles = [
  { id: 1, name: "Owner", children: [] },
  { id: 2, name: "Admin", children: [] },
  { id: 3, name: "Access Management", children: [] },
  {
    id: 4,
    name: "User Management",
    children: [
      { id: 1, name: "Individual User Management" },
      { id: 2, name: "Corporate User Management" },
    ],
  },
  { id: 5, name: "Payment Management", children: [] },
  {
    id: 9,
    name: "Course Management",
    children: [
      { id: 3, name: "Course" },
      { id: 4, name: "Test" },
    ],
  },
  {
    id: 10,
    name: "Content Management",
    children: [
      { id: 5, name: "Forum Management" },
      { id: 6, name: "Contact US Forms" },
    ],
  },
  { id: 11, name: "Dashboard Management", children: [] },
  { id: 12, name: "Form Management", children: [] },
  // { id: 13, name: "Form Purchases Management", children: [] },
];

const App = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [name, setName] = useState("");
  const [idData, setIdData] = useState(null);



  useLayoutEffect(() => {
    // Fetch data and preselect checkboxes
    GetAdminById(id)
      .then((res) => {
        const data = res.data.data;
        console.log("Fetched admin data:", data);
        setIdData(data);
        setName(data.name);

        // Parse roles and sub-roles from the API response
        const preselectedRoles = data.admin_roles.reduce((acc, role) => {
          if (role.admin_roles) {
            // Add main roles
            acc.add(role.admin_roles.name);
          }
          if (role.admin_sub_roles) {
            // Add sub-roles
            acc.add(role.admin_sub_roles.name);
          }
          return acc;
        }, new Set());

        console.log("Preselected roles:", Array.from(preselectedRoles));
        setSelectedRoles(Array.from(preselectedRoles));
      })
      .catch((err) => console.error("Error fetching data:", err));
  }, [id]);

  const updateSelectedRoles = (roleName, children, checked) => {
    const updatedRoles = new Set(selectedRoles);

    if (checked) {
      updatedRoles.add(roleName);
      children.forEach((child) => updatedRoles.add(child.name));
    } else {
      updatedRoles.delete(roleName);
      children.forEach((child) => updatedRoles.delete(child.name));
    }

    console.log("Updated roles in updateSelectedRoles:", Array.from(updatedRoles));
    return Array.from(updatedRoles);
  };

  const renderCheckbox = (role) => (
    <div key={role.name} style={{ marginBottom: "8px" }}>
      <Checkbox
        checked={selectedRoles.includes(role.name)}
        onChange={(e) =>
          handleParentChange(role.name, role.children, e.target.checked)
        }
      >
        {role.name}
      </Checkbox>
      {role.children && (
        <div style={{ paddingLeft: "20px", marginTop: "4px" }}>
          {role.children.map((child) => (
            <Checkbox
              key={child.name}
              checked={selectedRoles.includes(child.name)}
              onChange={(e) =>
                handleChildChange(role.name, child.name, e.target.checked)
              }
            >
              {child.name}
            </Checkbox>
          ))}
        </div>
      )}
    </div>
  );

  const handleParentChange = (roleName, children = [], checked) => {
	let updatedRoles = [...selectedRoles];
	console.log("Handling parent change:", roleName, checked);
  
	switch (roleName) {
	  case "Owner":
		updatedRoles = checked
		  ? roles
			  .map((role) => role.name)
			  .concat(
				roles.flatMap((role) => role.children.map((child) => child.name))
			  )
		  : [];
		break;
  
	  case "Admin":
		updatedRoles = checked
		  ? roles
			  .filter((role) => role.name !== "Owner")
			  .map((role) => role.name)
			  .concat(
				roles
				  .flatMap((role) => role.children.map((child) => child.name))
				  .filter((child) => child !== "Owner")
			  )
		  : selectedRoles.filter((role) => role === "Owner");
		break;
  
	  default:
		updatedRoles = updateSelectedRoles(roleName, children, checked);
		break;
	}
  
	console.log("Updated roles after parent change:", updatedRoles);
	setSelectedRoles(updatedRoles);
  };
  
  const handleChildChange = (parentName, childName, checked) => {
	const updatedRoles = new Set(selectedRoles);
	console.log("Handling child change:", parentName, childName, checked);
  
	if (checked) {
	  updatedRoles.add(childName);
  
	  const parent = roles.find((role) => role.name === parentName);
	  const allChildrenSelected = parent.children.every((child) =>
		updatedRoles.has(child.name)
	  );
	  if (allChildrenSelected) updatedRoles.add(parentName);
	} else {
	  updatedRoles.delete(childName);
	  updatedRoles.delete(parentName);
	}
  
	console.log("Updated roles after child change:", Array.from(updatedRoles));
	setSelectedRoles(Array.from(updatedRoles));
  };
  
const handleSubmit = (e) => {
  e.preventDefault();

  console.log("Selected Roles before submitting:", selectedRoles);

  // Arrays to hold role and sub-role IDs
  const roleIds = new Set();
  const subRoleIds = new Set();

  // Handle preselected roles
  if (idData?.admin_roles) {
    idData.admin_roles.forEach((role) => {
      if (role.admin_roles) {
        roleIds.add(role.admin_roles.id); // Add parent role ID
      }
      if (role.admin_sub_roles) {
        subRoleIds.add(role.admin_sub_roles.id); // Add sub-role ID
      }
    });
  }

  // Process current selected roles
  selectedRoles.forEach((item) => {
    // Find parent role
    const parentRole = roles.find((role) =>
      role.children.some((child) => child.name === item)
    );

    if (parentRole) {
      // Add sub-role ID
      const subRole = parentRole.children.find((child) => child.name === item);
      if (subRole) {
        subRoleIds.add(subRole.id);
      }
    } else {
      // Add parent role ID
      const role = roles.find((role) => role.name === item);
      if (role) {
        roleIds.add(role.id);
      }
    }
  });

  console.log("Final Role IDs:", Array.from(roleIds));
  console.log("Final SubRole IDs:", Array.from(subRoleIds));

  // Prepare form data
  const formData = new FormData();
  formData.append("name", name);
  formData.append("admin_id", id);

  // Append role IDs
  Array.from(roleIds).forEach((role_id) => {
    formData.append("role_id[]", role_id);
  });

  // Append sub-role IDs
  Array.from(subRoleIds).forEach((sub_role_id) => {
    formData.append("sub_role_id[]", sub_role_id);
  });

  // Send the data
  UpdateAdmin(formData)
    .then((res) => {
      if (res.status === 200) {
        message.success("Admin updated successfully!");
        navigate("/adminList");
      }
    })
    .catch((err) => {
      if (err.response && err.response.status === 401) {
        message.error("Token expired!");
        localStorage.removeItem("adminToken");
        setTimeout(() => {
          navigate("/Login");
        }, 3000);
      } else {
        message.error("Something went wrong");
      }
    });
};

  

  const navigateToAdmin = () => {
    navigate("/adminList");
  };

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <div>
          <h3 className="page-title">Admin MANAGEMENT</h3>
          <p className="page-sub-title">Update Admin Roles and Subroles</p>
        </div>
        <div>
          <Button
            icon="pi pi-arrow-left"
            severity="secondary"
            onClick={() => navigate("/adminList")}
            style={{ borderRadius: "5px", height: "47px" }}
          >
            Return to Admin List
          </Button>
        </div>
      </Box>

      <Card>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              value={name} // Set value to the 'name' state
              onChange={(e) => setName(e.target.value)} // Handle name change
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="text"
              defaultValue={idData?.email}
              name="email"
              disabled
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Select Roles:</Form.Label>
            {roles.map(renderCheckbox)}
          </Form.Group>
        </Form>

        <div style={{ marginTop: "50px" }}>
          <Button
            icon="pi pi-check"
            severity="info"
            htmlType="submit"
            type="primary"
            onClick={handleSubmit}
            style={{
              borderRadius: "5px",
              margin: "0px 0px",
              height: "40px",
            }}
          >
            Save
          </Button>

          <Button
            icon="pi pi-times"
            severity="secondary"
            onClick={navigateToAdmin}
            style={{
              borderRadius: "5px",
              marginLeft: "10px",
              height: "40px",
            }}
          >
            Cancel
          </Button>
        </div>
      </Card>
    </Box>
  );
};

export default App;
