/** @format */

import React, { useState, useEffect } from "react";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { AiOutlineDashboard } from "react-icons/ai";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { FaUsers } from "react-icons/fa";
import { SiCoursera } from "react-icons/si";
import { FiUser } from "react-icons/fi";
import { LuSettings } from "react-icons/lu";
import { FaRegCircleUser } from "react-icons/fa6";
import { Layout, theme, Menu, message } from "antd";
import { IoLayers } from "react-icons/io5";
import { LuLayers } from "react-icons/lu";
import { FiActivity } from "react-icons/fi";
import { MdCorporateFare, MdManageAccounts, MdQuiz } from "react-icons/md";
import { TbBrandCashapp } from "react-icons/tb";
import { CiLogin } from "react-icons/ci";
import Login from "../pages/Login/Login";
import { GetAdminProfile } from "../services/Api/Api";
import { FcBookmark } from "react-icons/fc";
import { IoInformationCircleSharp } from "react-icons/io5";
import { IoIosBookmark } from "react-icons/io";
import logo from "../assets/Logo.36423627a89e08a23c98.png";
import smallLogo from "../assets/small_logo.png";
import "./MainLayout.scss";

const { Header, Sider, Content } = Layout;

const MainLayout = () => {
	const [idData, setIdData] = useState({});
	const [userPermissions, setUserPermissions] = useState([]);
	const [collapsed, setCollapsed] = useState(false);

	const {
		token: { colorBgContainer },
	} = theme.useToken();
	const navigate = useNavigate();

	// Fetch user profile and extract roles
	const getData = async () => {
		try {
			let result = await GetAdminProfile(localStorage.getItem("adminToken"));
			if (result.status === 200) {
				setIdData(result.data.data);
				const permissions = result.data.data.admin_roles.map(
					(role) =>
						role.admin_roles?.abbreviation || role.admin_sub_roles?.abbreviation
				);
				setUserPermissions(permissions);
			} else if (
				result.status === 500 &&
				result.data.message === "jwt expired"
			) {
				navigate("/Login");
				message.error("Your session has expired. Please login again.");
			} else if (
				result.status === 403 &&
				result.data.message === "Admin not found."
			) {
				navigate("/Login");
				message.error(
					"Your account has been deleted by the admin. Please contact them for further assistance."
				);
			}
		} catch (e) {
			if (e.response) {
				if (
					e.response.status === 500 &&
					e.response.data.message === "jwt expired"
				) {
					navigate("/Login");
					message.error("Your session has expired. Please login again.");
				} else if (
					e.response.status === 403 &&
					e.response.data.message === "Admin not found."
				) {
					navigate("/Login");
					message.error(
						"Your account has been deleted by the admin. Please contact them for further assistance."
					);
				}
			} else {
				console.error(e);
			}
		}
	};

	useEffect(() => {
		getData();
		const interval = setInterval(() => {
			getData();
		}, 10000);
		return () => clearInterval(interval);
	}, []);

	function logout() {
		localStorage.removeItem("adminToken");
		message.success("Logged Out Successfully");
		setTimeout(() => {
			navigate("/Login");
		}, 1000);
	}

	const token = localStorage.getItem("adminToken");

	// Sidebar menu items
	const menuItems = [
		{
			key: "/dashboard",
			icon: <AiOutlineDashboard className="fs-4" />,
			label: "Dashboard",
			permission: "DASHBOARD_MANAGEMENT",
		},
		{
			key: "/users",
			icon: <FaUsers className="fs-4" />,
			label: "Users",
			permission: "USER_MANAGEMENT",
		},
		{
			key: "/corporate",
			icon: <MdCorporateFare className="fs-4" />,
			label: "Corporate",
			permission: "CORPORATE_USER_MANAGEMENT",
		},
		{
			key: "/course",
			icon: <SiCoursera className="fs-4" />,
			label: "Course",
			permission: "COURSE",
		},
		{
			key: "/test",
			icon: <MdQuiz className="fs-4" />,
			label: "Test",
			permission: "TEST",
		},
		{
			key: "/payment",
			icon: <TbBrandCashapp className="fs-4" />,
			label: "Payment",
			permission: "PAYMENT_MANAGEMENT",
		},
		{
			key: "/support",
			icon: <IoLayers className="fs-4" />,
			label: "Content",
			permission: "CONTENT_MANAGEMENT",
			children: [
				{
					key: "/forum",
					icon: <LuLayers className="fs-4" />,
					label: "Forum",
					permission: "FORUM_MANAGEMENT",
				},
				{
					key: "/contact_us",
					icon: <LuLayers className="fs-4" />,
					label: "Contact Us",
					permission: "CONTACT_US_FORMS",
				},
			],
		},
		{
			key: "/form",
			icon: <IoInformationCircleSharp className="fs-4" />,
			label: "Form",
			permission: "FORM_MANAGEMENT",
		},
		{
			key: "/adminList",
			icon: <MdManageAccounts className="fs-4" />,
			label: "Access Management",
			permission: "ACCESS_MANAGEMENT",
		},

		{
			key: "instruction-manual",
			icon: <IoIosBookmark className="fs-4" />,
			label: "Instruction Manual",
			onClick: () =>
				window.open(
					"https://nucleargauges.com/admin-panel-guidelines",
					"_blank"
				),
		},
	];

	// Map and alias permissions
	const aliasedPermissions = userPermissions.map((permission) => {
		if (permission === "INDIVIDUAL_USER_MANAGEMENT") return "USER_MANAGEMENT";
		return permission;
	});

	// Filter menu items dynamically
	const filteredMenuItems = menuItems
		.filter(
			(item) =>
				aliasedPermissions.includes(item.permission) ||
				(item.children &&
					item.children.some((child) =>
						aliasedPermissions.includes(child.permission)
					))
		)
		.map((item) => {
			if (item.children) {
				const filteredChildren = item.children.filter((child) =>
					aliasedPermissions.includes(child.permission)
				);
				return { ...item, children: filteredChildren };
			}
			return item;
		});

	// filteredMenuItems.push({
	//   key: "instruction-manual",
	//   icon: <FcBookmark className="fs-4" />,
	//   label: "Instruction Manual",
	//   onClick: () =>
	//     window.open("https://nucleargauges.com/admin-panel-guidelines", "_blank"),
	// });

	filteredMenuItems.push({
		icon: <IoIosBookmark className="fs-4" />,
		label: (
			<a
				href="https://nucleargauges.com/admin-panel-guidelines"
				target="_blank"
				rel="noopener noreferrer"
				onClick={(e) => e.stopPropagation()} // Prevents any propagation
				style={{ textDecoration: "none", color: "inherit" }} // Removes underline and matches text color
			>
				Instruction Manual
			</a>
		),
	});

	return (
		<>
			{token ? (
				<Layout>
					<Sider
						trigger={null}
						collapsible
						collapsed={collapsed}
						className="sidebar"
					>
						<div className="logo text-center py-2">
							{collapsed ? (
								<img
									src={smallLogo}
									className="logo-image"
									alt="logo"
									style={{
										width: "50px",
										// transition: "width 0.3s ease-in-out",
									}}
								/>
							) : (
								<img
									src={logo}
									className="logo-image"
									alt="logo"
									style={{
										width: "180px",
										// transition: "width 0.3s ease-in-out",
									}}
								/>
							)}
						</div>
						{/* <div className="logo">
							<div className="py-2 mb-0">
								<img src={logo} className="logo-image" alt="logo" />
							</div>
						</div> */}
						{/* <div className="logo text-center py-3">
							<img
								src={logo}
								alt="logo"
								style={{
									width: collapsed ? "50px" : "180px",
									justifySelf: "center",
									transition: "width 0.1s ease-in-out",
								}}
							/>
						</div> */}

						
						<Menu
							theme="dark"
							mode="inline"
							defaultSelectedKeys={[""]}
							onClick={({ key }) => {
								if (key === "signout") {
									logout();
								} else {
									navigate(key);
								}
							}}
							items={filteredMenuItems}
						/>
					</Sider>
					<Layout className="site-layout">
						<Header
							className="d-flex justify-content-between ps-1 pe-5"
							style={{
								padding: 0,
								background: colorBgContainer,
							}}
						>
							{React.createElement(
								collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
								{
									className: "trigger",
									onClick: () => setCollapsed(!collapsed),
								}
							)}
							<div className="d-flex gap-4 align-items-center">
								<div className="vertical-line"></div>
								<div className="position-relative">
									<div className="d-flex align-items-center">
										<div
											role="button"
											id="dropdownMenuLink"
											data-bs-toggle="dropdown"
											aria-expanded="false"
											className="d-flex align-items-center"
										>
											<FaRegCircleUser className="fs-3 me-2" />
											<div className="d-flex flex-column">
												{" "}
												<p className="mb-0" style={{ fontWeight: "700" }}>
													{idData?.name}
												</p>
												{/* <p className="mb-0">{idData?.admin_role?.name || ""}</p> */}
											</div>
										</div>
										<div
											className="dropdown-menu admin"
											aria-labelledby="dropdownMenuLink"
											style={{
												// margin: "10px !important",
												borderTopColor: "purple",
												borderTopWidth: "4px",
											}}
										>
											<li to="/viewAdmin">
												<Link
													className="dropdown-item py-1 mb-1"
													style={{ height: "auto", lineHeight: "30px" }}
													to="/viewAdmin"
												>
													<FiUser style={{ marginRight: "10px" }} />
													View Profile
												</Link>
											</li>
											<li>
												<Link
													className="dropdown-item py-1 mb-1"
													style={{ height: "auto", lineHeight: "30px" }}
													to="/settings"
												>
													<LuSettings style={{ marginRight: "10px" }} />
													Account Settings
												</Link>
											</li>
											<li>
												<Link
													className="dropdown-item py-1 mb-1"
													style={{ height: "auto", lineHeight: "30px" }}
													to="/loginLogs"
												>
													<FiActivity style={{ marginRight: "10px" }} />
													User Login Activity
												</Link>
											</li>
											<div className="dropdown-divider"></div>
											<li>
												<Link
													className="dropdown-item py-1 mb-1"
													style={{ height: "auto", lineHeight: "30px" }}
													onClick={() => logout()}
												>
													<CiLogin style={{ marginRight: "10px" }} />
													Sign Out
												</Link>
											</li>
										</div>
									</div>
								</div>
							</div>
						</Header>
						<Content
							style={{
								margin: "24px 16px",
								padding: 24,
								minHeight: 280,
								background: colorBgContainer,
							}}
						>
							<ToastContainer
								position="top-right"
								autoClose={250}
								hideProgressBar={false}
								newestOnTop={true}
								closeOnClick
								rtl={false}
								pauseOnFocusLoss
								draggable
								theme="light"
							/>
							<Outlet />
						</Content>
					</Layout>
				</Layout>
			) : (
				<Login />
			)}
			<ToastContainer />
		</>
	);
};

export default MainLayout;
